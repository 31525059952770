<template>
  <div>
    <Modal :idModal="idModal" size="lg">
      <div class="container">
        <div class="row p-3">
          <div class="col-12 d-flex justify-content-between align-items-center">
            <p class="text-center m-0 title-modal">Detalhes</p>
            <img
              class="float-right"
              :src="require('@/assets/icons/close.svg')"
              alt="Voltar"
              @click="$bvModal.hide(idModal)"
              id="icon-close"
            />
          </div>

          <div class="row col-12 modal-content-info-lead">
            
              <div class="col-5">
                <p class="title-item-modal status-leadgen">Status</p>
                <span class="info-leadgen" :class="`status-` + leadgen.status">
                  {{ status }}
                </span>
              </div>
              <div class="col-7">
                <p class="title-item-modal">Data de criação</p>
                <span class="info-leadgen">{{ leadgen.created_at }}</span>
              </div>

              <div class="row col-12" v-if="statusLeadResponse">
                <div class="mt-5 row col-12">
                  <div class="col-4 mt-3" v-if="lead.nome">
                    <p class="mb-2 title-item-modal">Nome do Lead</p>
                    <span class="info-leadgen">{{ lead.nome }}</span>
                  </div>
                  <div class="col-4 mt-3" v-if="lead.telefone">
                    <p class="mb-2 title-item-modal">Telefone</p>
                    <span class="info-leadgen">{{ lead.telefone }}</span>
                  </div>
                  <div class="col-4 mt-3" v-if="lead.email">
                    <p class="mb-2 title-item-modal">Endereço de E-mail</p>
                    <span class="info-leadgen">{{ lead.email }}</span>
                  </div>
                </div>
                <div class="mt-2 row col-12" v-if="lead.adicionais[0]">
                  <div class="col-4 mt-3" v-if="lead.nome">
                    <p class="mb-2 title-item-modal">Adicionais</p>
                  </div>
                </div>
                <div class="mt-1 row col-12" v-if="lead.adicionais[0]">
                  <div
                    class="col-4 mt-3"
                    v-for="adicional of lead.adicionais"
                    :key="adicional.ID"
                  >
                    <p class="mb-2 title-item-modal">{{ adicional.nome }}</p>
                    <span class="info-leadgen">{{ adicional.valor }}</span>
                  </div>
                </div>
              </div>
              <div class="row col-12" v-else>
                <div class="mt-2 row col-12">
                  <div class="col-12 mt-3">
                    <p class="mb-2 title-item-modal">{{ messageError }}</p>
                  </div>
                </div>
            </div>
          </div>
          <button
            class="btn btn-lead btn-reprocess-lead"
            @click="reprocessLead"
            v-if="this.leadgen.status !== 'success' && this.leadgen.status !== 'queued'"
          >
            {{nameBtnError}}
          </button>
          <button v-else
            class="btn btn-lead btn-view-lead"
            @click="openPageLead"
          >
            Visualizar Lead
          </button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "../BaseModal.vue";
import moment from "moment";
import { mapMutations } from "vuex";

import LeadService from "@/services/resources/LeadService.js";
const LeadServiceApi = LeadService.build();
import LeadAdLeadService from "@/services/resources/LeadAdLeadService.js";
const LeadAdLeadServiceApi = LeadAdLeadService.build();
import RedirectService from "@/services/resources/RedirectService";
const service = RedirectService.build();

export default {
  name: "ModalViewLeadgen",
  props: {
    idModal: {
      required: true,
    },
    leadgen: {
      required: false,
    },
  },
  data: () => ({
    status: "",
    lead: { adicionais: [] },
    statusLeadResponse: true,
    messageError: "",
    nameBtnError:''
  }),
  components: {
    Modal,
  },
  watch: {
    leadgen: async function () {
      this.loading(true)
      let responseRaw = null;
      if(this.leadgen.status === 'queued'){
        responseRaw = {status: false, erro: 'Lead em preocessamento'}

      }else if(this.leadgen.raw_response){
        responseRaw = JSON.parse(this.leadgen.raw_response)
      }else{
        responseRaw = {status: false}
      }

      if (!responseRaw.status && !this.leadgen.lead_id) {
        this.statusLeadResponse = responseRaw.status;
        this.messageError = responseRaw.erro;
      } else {
        this.lead = await LeadServiceApi.read(this.leadgen.lead_id);
      }
      
      this.leadgen.created_at = moment(this.leadgen.created_at).format(
        "DD/MM/YYYY HH:MM"
      );

      switch (this.leadgen.status) {
        case "success":
          
          this.status = "Lead cadastrado";
          break;
        case "token_error":
          if(this.leadgen.status === 'token_error' && this.leadgen.fanpage_status === 'token_error'){
              this.nameBtnError = 'Atualize o token da fanpage primeiro';
          }else{
            this.nameBtnError = 'Reprocessar Lead';
          }
          
          this.status = "Lead com erro de token";
          break;
        case "error":
          this.nameBtnError = 'Reprocessar Lead';
          this.status = "Lead com erro";
          break;
        default:
          this.status = "Em processamento";
          this.nameBtnError = 'Lead esta em processamento'
      }
      this.loading(false)
    },
  },
  methods: {
    ...mapMutations(["loading", 'setMessage']),
    openPageLead(){
      service.create({}).then((resp) => {
        let url = "https://" + resp.domain + "/gadmin/lead/index.php?id="+this.lead.id;
         window.open(url, '_blank').focus();
      });
    },
    async reprocessLead() {

      if(this.leadgen.status === 'token_error' && this.leadgen.fanpage_status === 'token_error'){
        this.$router.push('/lead-ads/fanpage/'+this.leadgen.fanpage_id)
        return '';
      }

      this.loading(true)
      let reprocess = await LeadAdLeadServiceApi.put(
        "/reprocess-lead/" + this.leadgen.id,
        {}
      );
      if(reprocess == '1'){
        this.$bvModal.hide(this.idModal)
        this.$emit('reprocessedLead')
        this.setMessage({message:'envio de reprocessamento de lead efetuada com sucesso.', bg:'success'})

      }else{
        this.setMessage({message:'Falha ao reprocessar lead.', bg:'danger'})
      }
      this.restartData();
      this.loading(false)
    },
    restartData(){
      this.status = "",
      this.lead =  { adicionais: [] },
      this.statusLeadResponse = true,
      this.messageError = "",
      this.nameBtnError = ''
    },
  },

};
</script>

<style >
.status-success {
  color: #00e4a0 !important;
  font-weight: 600;
}

.status-queued {
  color: #ffcb37 !important;
  font-weight: 600;
}

.status-error,
.status-token_error {
  color: red !important;
  font-weight: 600;
}

.info-leadgen {
  font-size: 13px;
  line-height: 16px;
  color: #8c8a97;
}
.btn-reprocess-lead {
  background-color: #ff0c37;
}

.btn-lead {
  margin-top: 67px;
  width: 173.55px;
  height: 45px;
  left: 34px;
  top: 292.62px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #ffffff;
  margin: 15px 5px 0 5px;
}
.btn-view-lead {
  background: #000000;
}
.btn-lead:hover {
  color: #ffffff;
}

#icon-close {
  cursor: pointer;
}

.info-status-leadgen {
  font-weight: 600 !important;
  color: #00e4a0 !important;
}

.modal-content-info-lead {
  margin-top: 70px;
}

.title-modal {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.title-item-modal {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #333333;
}
</style>