<template>
  <div class="content">
    <Header>
      <template v-slot:link>
        <router-link 
          :to="getUrlToIndexFanpage"
          class="link-page d-inline"
        >
          {{getNameToUrlFanpage}}
        </router-link>
        <div class="d-inline text-item"> / Histórico de LeadAds</div>
      </template>
    </Header>

    <div class="row col-12 p-0">
      <div class="col-12 p-0 d-flex justify-content-between">
        <div class="content-btn-filter">
          <b-dropdown
            text="Fanpage"
            class="btn-filter-fanpage"
            variant="filter"
            toggleText=""
            id="drop-fanpages"
          >
            <template #button-content>
              <img
                :src="require('@/assets/icons/fanpageGrenn.svg')"
                alt="Fanpage"
                class="mr-1"
              />
              {{getNameFanpageFiltered}}
            </template>

            <b-dropdown-item
              href="#"
              v-for="dataFanpage of dataFanpages"
              :key="dataFanpage.id"
              :active="activeFanpageSelected(dataFanpage.id)"
              @click="filterForFanpage(dataFanpage)"
            >
              <div
                :style="{
                  'background-image': `url(${dataFanpage.fb_link_img})`,
                }"
                class="img-page"
              ></div>

              {{ dataFanpage.fb_fanpage_title }}
            </b-dropdown-item>
          </b-dropdown>

          <b-dropdown
            text="Fanpage"
            class="btn-filter-integration"
            variant="filter"
            toggleText=""
            :class="getClassToDisabeldBtnFilter"
            :disabled="getClassToDisabeldBtnFilter !== ''"
          >

            <template v-if="integrationId" #button-content >
              {{ getNameFormFiltered.form_fb }}
                  <img :src="facebookBlack" alt="Facebook" class="m-0" />
                  <img :src="lightningGreen" alt="Integração" class="m-0" />
                  <img :src="gDigital" alt="Integração" class="m-0" />
              {{ getNameFormFiltered.form_g }}
            </template>

            <template v-else #button-content >
              <img :src="lightningGreen" alt="Integrações" class="mr-1" />
              Integrações
            </template>

            <b-dropdown-item
              href="#"
              v-for="dataForm of dataForms"
              :key="dataForm.id"
              :active="
                activeFormIntegrationSelected(
                  dataForm.fanpage_form_integration.id
                )
              "
              @click="filterForForm(dataForm)"
            >
              {{ dataForm.fb_form_title }}
              <img :src="facebookBlack" alt="Facebook" class="m-0" />
              <img :src="lightningGreen" alt="Integração" class="m-0" />
              <img :src="gDigital" alt="Integração" class="m-0" />
              {{ dataForm.form_g_digital }}
            </b-dropdown-item>
          </b-dropdown>

          <b-button 
            class="btn-clear-filter"
           variant="filter"
           @click="clearFilter"
           v-if="fanpageId"
           >Limpar Filtros</b-button>
        </div>
        <div class="pr-3 content-total-integrations">
          <span class="total-integration">{{totalIntegrations}} Integração encontradas</span>
        </div>
      </div>
    </div>

    <div class="content-table">
      <b-table
        :borderless="true"
        :items="items"
        :fields="fields"
        :fixed="false"
        show-empty
      >
       <template #empty="scope">
          <TableEmpty 
          text="Nenhuma LeadAds foi encontrata "
          />
      </template>

        <template #cell(status)="row"> 
           <div class="status-lead " 
           :class="{['status-'+row.item.status]: true}" 
           :title="getTitleStatus(row.item.status)">
          </div> 
        </template>

        <template #cell(id)="row"> # {{ row.item.id }} </template>

        <template #cell(integrations)="row">
          <div class="d-flex align-items-center">
            <span class="d-inline-block text-truncate" style="max-width: 150px;" :title="row.item.integration" >{{row.item.integration}}</span>
            <img :src="facebookBlack" alt="Facebook" />
            <img :src="lightningGreen" alt="Integração" />
            <img :src="gDigital" alt="Integração" />
            <span class="d-inline-block text-truncate" style="max-width: 150px;" :title="row.item.form_g_digital" >{{row.item.form_g_digital}}</span>
          </div>
        </template>

        <template #cell(actions)="row">
          <b-link 
           size="sm" class="mr-1 link-action-view" 
            @click="getLeadgen(row.item)"
           >
            VISUALIZAR DETALHES
          </b-link>
        </template>
      </b-table>

      <ModalViewLeadgen :idModal="idModalviewLeadgen" 
      :leadgen="leadgen" @reprocessedLead="dataLeads"/>

      <Footer
        v-model="currentPage"
        :rows="getTotalRows"
        :perPage="getPerPage"
        @change="dataLeads"
      />
    </div>
  </div>
</template>

<script>
import Header from "./Header.vue";
import ModalViewLeadgen from './ModalViewLeadgen.vue';
import Footer from './Footer.vue'
import TableEmpty from './TableEmpty.vue'

import LeadAdService from "@/services/resources/LeadAdService.js";
const LeadAdApiService = LeadAdService.build();
import { mapMutations } from "vuex";

import moment from "moment";

export default {
  name: "HistoricForLeadAd",
  components: {
    Header,
    ModalViewLeadgen,
    TableEmpty,
    Footer
  },
  data: () => ({
    lightningGreen: require("@/assets/icons/lightningGreen.svg"),
    facebookBlack: require("@/assets/icons/facebookBlack.svg"),
    gDigital: require("@/assets/icons/gDigital.svg"),
    fields: [
      { key: "status", label: "" },
      { key: "id", label: "# ID" },
      "Criado em",
      "Atualizado em",
      { key: "integrations", label: "Integração" },
      "Fanpage",
      { key: "actions", label: "Ações" },
    ],
    items: [],
    fanpageId: null,
    integrationId: null,
    numberPage: 0,
    currentPage: 1,
    getTotalRows: 0,
    getPerPage: 0,
    dataFanpages: [],
    dataForms: [],
    totalIntegrations: 0,
    idModalviewLeadgen: 'modal-view-leadgen',
    leadgen: {},
    nameIntegrationFiltered: {
      form_g: '',
      form_fb: ''
    },
  }),
  watch: {
    $route: function (to) {
      let params = to.params;
      if (!params.idFanpage && !params.idIntegration) {
        this.fanpageId = null;
        this.integrationId = null;
        this.dataForms = []
        this.dataLeads();
      }
    },
  },
  computed: {
    activeFanpageSelected: function () {
      return (id) => {
        return +this.fanpageId === +id;
      };
    },
    activeFormIntegrationSelected: function () {
      return (id) => {
        return +this.integrationId === +id;
      };
    },
    getUrlToIndexFanpage: function () {
      if (this.fanpageId) {
        return "/lead-ads/fanpage/" + this.fanpageId;
      }
      return "/lead-ads";
    },
    getNameToUrlFanpage: function () {
      if (this.fanpageId) {
        return "Fanpage";
      }
      return "Listagem de Fanpages";
    },
    activeBtnViewHistoric: function(){
      return (status)=>{
        switch(status){
          case "success":
          case "token_error":
          case "error":
          return true;
        }
      };
    },
    getClassToDisabeldBtnFilter: function(){
      if(!this.fanpageId){
        return 'btn-filter-disabled';
      }
      return ''
    },
    getTitleStatus: function(){
      return (status)=>{
        switch(status){
          case 'success':
            return 'Sucesso'
          case 'token_error':
            return 'Erro de token'
          case 'error':
            return 'Erro'
          default:
            return 'Em processamento'
        }
      }
    },
    getNameFanpageFiltered(){
      if(this.fanpageId){
        let data = this.dataFanpages.find((item)=>(+item.id === +this.fanpageId))
        if(data){
          return data.fb_fanpage_title
        }
      }
      return 'Fanpage';
    },
    getNameFormFiltered(){
      if(this.integrationId){
        let data = this.dataForms.find((item)=>(+item.fanpage_form_integration.id === +this.integrationId))
        if(data){
            return {
            form_fb: data.fb_form_title,
            form_g: data.form_g_digital
          }
        }
      }
      return ''
    },
    
  },
  methods: {
    ...mapMutations(["loading", 'setMessage']),
    async getLeadgen(leadgenData) {
      
      this.loading(true)
      
      const leadgen = await LeadAdApiService.read('forms/leadgen/'+leadgenData.fb_leadgen_id)
      
      if(leadgen.message_error){
        this.setMessage({message: leadgen.message_error})
        this.loading(false)
        return
      }
      this.leadgen = Object.assign(leadgen, {fanpage_id: leadgenData.fanpage_id, fanpage_status: leadgenData.fanpage_status});
  
      this.$bvModal.show(this.idModalviewLeadgen)
      this.loading(false)
    },
    setDataForPaginate(leads) {
      this.currentPage = leads.current_page;
      this.getTotalRows = leads.total;
      this.getPerPage = leads.per_page;
    },
    async dataLeads(page) {
      
      this.loading(true);
      if (!page && !this.numberPage) {
        this.numberPage = 1;
      } else if (page !== undefined) {
        this.numberPage = page;
      }

      this.clearIdsFilter();
      try{
        const response = await LeadAdApiService.read(
          `leadgen/${this.fanpageId}/${this.integrationId}?page=${this.numberPage}`
        );
        this.setDataForPaginate(response)
        let totalIntegrations = response.data.pop();
        
        if(response.error){
          this.loading(false)
          this.setMessage({message:'Falha ao buscar dados de leads.', bg:'danger'})
          return {}
        }
        this.totalIntegrations = totalIntegrations.totalIntegrations ? totalIntegrations.totalIntegrations : 0

        this.formatData(response);
        this.loading(false);  
        return response;
      }catch(e){

        this.setMessage({message: 'Falha ao carregar dados de leadgens.', bg:'danger'})
        console.log(e)
      }
      this.loading(false);
    },
    clearIdsFilter(){
      if (!this.fanpageId) {
        this.fanpageId = "";
      }
      if (!this.integrationId) {
        this.integrationId = "";
      }
    },
    clearFilter(){
      let partUri = `/lead-ads/leadgen/`;
      this.$router.push(partUri);
    },
    formatData(leads) {
      try{
        this.items = [];
        for (let lead of leads.data) {
          this.items.push({
            id: lead.id,
            "Criado em": moment(lead.created_at).format("DD/MM/YYYY HH:MM"),
            "Atualizado em": moment(lead.updated_at).format("DD/MM/YYYY HH:MM"),
            integration: lead.fb_form_title,
            Fanpage: lead.fb_fanpage_title,
            "Último Lead Cap.": lead.last_catch ?? "Aguardando Leads",
            fb_form_title: lead.fb_form_title,
            form_g_digital: lead.form_g_digital,
            status: lead.status,
            fb_leadgen_id: lead.fb_leadgen_id,
            fanpage_id:lead.fanpage_id,
            fanpage_status: lead.fanpage_status
          });
        }
      }catch(e){
        console.log(e)
        this.loading(false)
        this.setMessage({message:'Falha ao Formatar dados de leads'})
      }
    },
    async dataFilterForm() {
      this.dataForms = [];
      if (this.fanpageId) {
        this.dataForms = await LeadAdApiService.read(
          `forms/to-filter/${this.fanpageId}`
        );
      }
    },
    async dataFilterFanpage() {
      this.dataFanpages = await LeadAdApiService.read(`all`);
    },
    filterForFanpage(event) {
      this.fanpageId = event.id;
      let partUri = `/lead-ads/${this.fanpageId}/leadgen/`;
      if (this.$route.path != partUri) {
        this.integrationId = null;
        this.dataLeads();
        this.dataFilterForm();
        this.$router.push(partUri);
      }
    },
    filterForForm(event) {
      this.integrationId = event.fanpage_form_integration.id;
      let partUri = `/lead-ads/${this.fanpageId}/leadgen/${this.integrationId}`;
      if (this.$route.path != partUri) {
        this.$router.push(partUri);
        this.dataLeads();
      }
    },
    
  },
  mounted: async function () {
    this.fanpageId = this.$route.params.idFanpage;
    this.integrationId = this.$route.params.idIntegration;
    this.dataFilterFanpage();
    this.dataFilterForm();
    this.dataLeads();
  },
};
</script>

<style scoped>

.total-integration{
  margin-top:40px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #81858E;
}

.status-lead{
  width: 6px;
  height: 6px;
  left: 119.7px;
  top: 307.29px;
  border-radius: 50%;
}

.status-success{
  background: #00E4A0;
}

.status-queued{
  background-color: #FFCB37;
}

.status-error, .status-token_error{
  background-color: red;
}
img {
  padding: 5px;
}

.img-page {
  background-size: 100% 80%;
  background-position: 50%;
  background-repeat: no-repeat;
  height: 45px;
  width: 41px;
  display: inline-flex;
  margin-right: 25.9px;
}
</style>

<style >

.btn-filter-disabled>button{
  background-color: #efeded !important;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #ede8e8;
}

td:first-child>div {
  margin-top: 5px; 
}

.dropdown-menu {
  border: 0 !important;
  box-shadow: 8px 8px 8px rgba(161, 159, 159, 0.1);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
}
.btn-filter + ul {
  padding: 0 !important;
}
.btn-filter + .dropdown-menu > li > a {
  padding: 14px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  display: flex;
  align-items: center;
}

.link-action-view {
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.2px;
  color: #007bff;

}
.link-action-view:hover {
  color: #007bff;
  text-decoration: none;
}

.btn-filter::after {
  opacity: 0;
}

tr > td {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #81858e;
}

.btn-filter {
  height: 40px;
  background: rgba(0, 228, 160, 0.1);
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #00e4a0;
  border: 0;
  margin: 17px 15px;
}

.btn-filter:hover {
  color: #00e4a0;
}

.content-table {
  margin-top: 33px;
}

th {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: #333333;
}
/* .active {
  color: white !important;
} */
</style>