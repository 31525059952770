<template>
  <Content>
      <HistoricForLeadAd/>
  </Content>
</template>
<script>
import Content from "@/components/LeadAd/Content.vue";
import HistoricForLeadAd from "@/components/LeadAd/HistoricForLeadAd.vue";

export default {
  components: {
    Content,
    HistoricForLeadAd
  },
};
</script>